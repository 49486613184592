// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');

@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';

// custom css
@import "../css/app.css";
